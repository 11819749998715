import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const TitleContainer = styled.div`
  color: ${COLORS.DARK_GREY};
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding-top: 24px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    row-gap: 5px;
    max-width: 20ch;
    text-align: center;
  }
`;

export const QuestionTitle = styled.h2`
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  opacity: 0.9;
`;

export const ContinueButton = styled.button`
  padding: 17px 32px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};
  border-radius: 999px;
  background: transparent;
  text-decoration: none;
`;

export const QuestionsContainer = styled.div`
  padding-block: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    row-gap: 60px;
    padding-block: 40px;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 25px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    position: fixed;
    bottom: 0;
    padding-block: 12px;
    box-shadow: 0px 0px 90px 0px ${COLORS.BLACK}33;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${COLORS.WHITE};
    z-index: 2;
  }
`;

export const LegalInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  opacity: 0.9;
  color: ${COLORS.DARK_GREY};
  text-align: center;
  padding-top: 30px;
  padding-bottom: 24px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-top: unset;
  }
`;

export const PrivacyPolicyLink = styled.a`
  color: ${COLORS.DARK_GREY};
`;
