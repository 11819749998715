import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';
import { Link } from 'gatsby';

export const MainTitle = styled.h1`
  font-size: 80px;
  line-height: 78px;
  color: ${COLORS.WHITE};
  font-weight: 500;
  margin: 0;
  padding-top: 150px;
  padding-inline: 60px;

  position: relative;
  z-index: 20;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    font-size: 55px;
    line-height: 55px;
    padding-top: 100px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: 46px;
    line-height: 46px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    padding-inline: 24px;
    padding-bottom: 350px;
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: 350px;
    padding-top: 120px;
  }
`;

export const SecondaryTitle = styled.h2`
  font-size: 32px;
  line-height: 32px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 46px;
    line-height: 46px;
  }

  font-weight: 500;
  margin: 0;
  padding-bottom: 30px;
`;

export const TextContainer = styled.div`
  color: ${COLORS.DARK_GREY};
  font-weight: 400;
  max-width: 580px;
  padding-bottom: 45px;

  & > p + p {
    margin-top: 20px;
  }

  & p:last-child {
    font-weight: 500;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & a:first-child {
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.BLUE};
    border-radius: 999px;
    padding: 17px 32px;
    width: fit-content;
    text-decoration: none;
  }

  & a:last-child {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLUE};
    border: 1px solid ${COLORS.BLUE};
    border-radius: 999px;
    padding: 17px 32px;
    width: fit-content;
    text-decoration: none;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 18px;
  }
`;

export const ImageSquare = styled.img`
  position: absolute;
  left: -180px;
  bottom: -200px;
  width: 550px;
  height: auto;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 400px;
  }
`;

export const ImageDotted = styled.img`
  position: absolute;
  bottom: -60px;
  right: -20px;
  width: 420px;
  height: auto;
  z-index: 2;
  transform: rotate(20deg);

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 400px;
    bottom: -80px;
    transform: scaleY(1) scaleX(-1) rotate(-110deg);
  }
`;

export const FirstWelcomeImageContainer = styled.div`
  position: absolute;
  bottom: 70px;
  left: -120px;
  width: 65%;
  transform: rotate(4.5deg);
  height: auto;
  z-index: 10;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    max-width: 400px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 300px;
    left: -80px;
  }
`;

export const SecondWelcomeImageContainer = styled.div`
  position: absolute;
  bottom: -140px;
  right: -60px;
  width: 55%;
  height: auto;
  transform: rotate(-4deg);
  z-index: 9;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    max-width: 400px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 320px;
    bottom: -30px;
  }
`;

export const RightSide = styled.div`
  background: ${COLORS.WHITE};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 24px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding-block: 38px;
    width: auto;
    flex-grow: 1;
  }
`;

export const LeftSide = styled.div`
  background: ${COLORS.BLUE};
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 100vh;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column;
    height: auto;
  }
`;

export const BackgroundRectangle = styled.div`
  background: ${(props) => (props.side === 'left' ? COLORS.YELLOW : COLORS.RED)};
  width: 100%;
  height: 100%;
  position: absolute;
  left: ${(props) => (props.side === 'left' ? '8px' : '80px')};
  ${(props) => {
    if (props.side === 'left') {
      return `top: 8px;`;
    } else {
      return `bottom: 12px;`;
    }
  }}

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    left: ${(props) => props.side === 'right' && '80px'};
  }
`;

export const InternalLink = styled(Link)`
  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin: 0 auto;
  }
`;

export const ExternalLink = styled.a`
  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    margin: 0 auto;
  }
`;

export const ImageNetwork = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;
