import React from 'react';

import * as Styled from './styles';

const StepInputRadio = ({
  label,
  register,
  watch,
  step,
  options,
  id,
  errors,
  inputType,
  sm = false,
}) => {
  const selectedValue = watch(id);

  return (
    <div>
      <Styled.Legend>{label}</Styled.Legend>

      <Styled.AnswersContainer inputType={inputType}>
        {parseInt(step) === 2 && <Styled.Line />}
        {options?.map((option, idx) => {
          return (
            <Styled.RadioButtonContainer key={idx} inputType={inputType}>
              <div>
                {inputType === 'underlined' ? (
                  <Styled.UnderlinedRadioType
                    type="radio"
                    id={`${id}-${option.id}`}
                    name={id}
                    value={option.id}
                    {...register(id)}
                    className="sr-only"
                    required
                  />
                ) : (
                  <Styled.CheckboxRadioType
                    type="radio"
                    id={`${id}-${option.id}`}
                    name={id}
                    value={option.id}
                    {...register(id)}
                    className="sr-only"
                    required
                  />
                )}
                <Styled.InputLabel htmlFor={`${id}-${option.id}`} sm={sm}>
                  {option.label}
                </Styled.InputLabel>
                {option?.description && (
                  <Styled.Description>{option?.description}</Styled.Description>
                )}
              </div>
              {inputType === 'check' && (
                <Styled.CheckboxMark isActive={Number(selectedValue) === option.id}>
                  {Number(selectedValue) === option.id && (
                    <Styled.Checkmark src="/images/check.png" alt=""></Styled.Checkmark>
                  )}
                </Styled.CheckboxMark>
              )}
            </Styled.RadioButtonContainer>
          );
        })}
      </Styled.AnswersContainer>

      {errors?.[id]?.message && (
        <p style={{ color: 'red', fontSize: '0.75rem' }}>{errors?.[id]?.message}</p>
      )}
    </div>
  );
};

export default StepInputRadio;
