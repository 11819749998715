import React from 'react';

import * as Styled from './styles';
import SocialMediaContainer from '../../SocialMediaContainer';
import { COLORS } from '../../../utils/theme';
import { WELCOME_FINAL_OPTIONS } from '../../../utils/constants';
import BelieversForm from '../BelieversForm';
import Newsletter from '../../NewRegisterForm/Newsletter';
import BeginRegistration from '../BeginRegistration';

const FourthStep = ({ finalStep }) => {
  const pageOptions = {
    [WELCOME_FINAL_OPTIONS.BELIEVER]: {
      title: 'Greetings!',
      description: [
        'Thank you for your interest in Community Arts Network (CAN) – we are delighted that you, like us, believe in the power of the arts to transform our world!',
        'CAN is a space in which you can be <span>inspired</span> by diverse and amazing changemakers and initiatives...and <span>informed</span> by insightful content, information, resources, and useful connections in our network.',
      ],
    },
    [WELCOME_FINAL_OPTIONS.CURIOUS]: {
      href: '/network-map',
      openNewTab: true,
      label: 'Explore the Network',
      title: 'Stay connected!',
      socialMedia: 'Follow us!',
      description: [
        'We are delighted you’re curious about arts for social impact/community arts, and interested in CAN!',
        'Our network comprises registered members – people who actively use arts for social impact in their work - and others, like yourself, who are interested in learning more about and supporting the transformative power of the arts.',
        'We would love to stay connected – your support is important! Please follow us on social media to keep up with what’s happening in the network and for engaging information, inspiring profiles of changemakers, exciting opportunities to get involved, and much, much more!',
      ],
    },
  };

  return finalStep === WELCOME_FINAL_OPTIONS.ACTOR ? (
    <BeginRegistration />
  ) : (
    <Styled.GlobalContainer finalStep={finalStep}>
      <Styled.MainContainer>
        <Styled.TitleContainer finalStep={finalStep}>
          <Styled.Title finalStep={finalStep}>{pageOptions[finalStep]?.title}</Styled.Title>

          {finalStep === WELCOME_FINAL_OPTIONS.BELIEVER && (
            <Styled.GreetingLine src="/images/greeting-line.png" width="554" height="70" alt="" />
          )}
        </Styled.TitleContainer>
        <Styled.DescriptionContainer>
          {pageOptions[finalStep]?.description?.map((descriptionEl) => {
            return <Styled.Description dangerouslySetInnerHTML={{ __html: descriptionEl }} />;
          })}
        </Styled.DescriptionContainer>

        {finalStep === WELCOME_FINAL_OPTIONS.BELIEVER && (
          <Styled.BelieversContainer>
            <Styled.LeftContainer>
              <Styled.SecondaryTitle>
                If you are keen to practice arts for social impact, here’s what we have to offer!
              </Styled.SecondaryTitle>
              <ul style={{ margin: 0, padding: 0 }}>
                <Styled.SupportListItem>
                  <p>
                    Interested in making <span>connections</span>?
                  </p>
                  <p>
                    Check out our{' '}
                    <a href="/network-map" target="_blank">
                      Network Map & Matchmaker
                    </a>{' '}
                    to get to know CAN members.
                  </p>
                </Styled.SupportListItem>
                <Styled.SupportListItem>
                  <p>
                    Seeking specialist <span>knowledge</span> and information on arts for social
                    impact?
                  </p>
                  <p>
                    Visit our online{' '}
                    <a href="/knowledge-library/" target="_blank">
                      Knowledge Hub - Library
                    </a>
                  </p>
                </Styled.SupportListItem>
                <Styled.SupportListItem>
                  <p>
                    Keen to meet exciting changemakers and access awesome{' '}
                    <span>funding opportunities</span>?
                  </p>
                  <p>
                    Subscribe to our{' '}
                    <a
                      href="https://5fcb60a5.sibforms.com/serve/MUIEAKaP3_Z2IKkdcRuZOXmfcKP13umesozFkRUK9B9m-fJZYh3sIEuHP_zeDDynxXsWZO2PsTqmFappuIUUwpoGK9-PvcP75idZHtLSqNrCFLg2UujY4UlaTUZhlM_BnsJ10t52Xwkv34W_iAk6-1Y4Q-A31UGjz140afWtWDE1dQUu0CWU3em9sRef9Owbvmy8FQyenDoXnX-Z"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Newsletter
                    </a>
                  </p>
                </Styled.SupportListItem>
                <Styled.SupportListItem>
                  <p>
                    Want to engage compelling content from the network and beyond, to inform,
                    inspire and motivate you?
                  </p>
                  <p>
                    Follow us on{' '}
                    <a
                      href="https://www.instagram.com/commartsnetwork/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    ,{' '}
                    <a
                      href="https://www.linkedin.com/company/cannetwork/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                    ,{' '}
                    <a
                      href="https://www.facebook.com/CommunityArtsNetwork"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    ,{' '}
                    <a href="https://x.com/CommArtsNetwork" target="_blank" rel="noreferrer">
                      X
                    </a>
                    , and{' '}
                    <a
                      href="https://www.youtube.com/channel/UCF5XgVBjbF95lVo5x3KX84w"
                      target="_blank"
                      rel="noreferrer"
                    >
                      YouTube
                    </a>
                    .
                  </p>
                </Styled.SupportListItem>
              </ul>

              <p
                style={{
                  margin: 0,
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 700,
                  paddingBottom: '5px',
                  color: COLORS.BLACK,
                }}
              >
                Would you like to be in touch with us directly?
              </p>
              <p
                style={{ margin: 0, fontSize: '14px', lineHeight: '20px', color: COLORS.DARK_GREY }}
              >
                Email:{' '}
                <a href="mailto:info@community-arts.net" style={{ color: COLORS.BLUE }}>
                  info@community-arts.net
                </a>
              </p>
            </Styled.LeftContainer>
            <Styled.RightContainer>
              <BelieversForm />
            </Styled.RightContainer>
          </Styled.BelieversContainer>
        )}

        {pageOptions[finalStep]?.href && (
          <Styled.LinkContainer finalStep={finalStep}>
            {pageOptions[finalStep]?.openNewTab ? (
              <Styled.ExternalLink href={pageOptions[finalStep]?.href} target="_blank">
                {pageOptions[finalStep]?.label}
              </Styled.ExternalLink>
            ) : (
              <Styled.InternalLink to={pageOptions[finalStep]?.href}>
                {pageOptions[finalStep]?.label}
              </Styled.InternalLink>
            )}
          </Styled.LinkContainer>
        )}

        {finalStep === WELCOME_FINAL_OPTIONS.CURIOUS && (
          <div style={{ marginTop: '77px' }}>
            <Newsletter />
          </div>
        )}
      </Styled.MainContainer>

      {pageOptions[finalStep]?.socialMedia && (
        <SocialMediaContainer label={pageOptions[finalStep]?.socialMedia}></SocialMediaContainer>
      )}
    </Styled.GlobalContainer>
  );
};

export default FourthStep;
