import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const TitleContainer = styled.div`
  position: relative;
  padding-bottom: 36px;
  width: fit-content;
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 56px;
  margin: 0;
  font-weight: 400;
  color: ${COLORS.WHITE};

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    font-size: 60px;
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: 50px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 40px;
    line-height: 30px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  color: ${COLORS.WHITE};

  & > a {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLUE};
    border: 1px solid ${COLORS.BLUE};
    border-radius: 999px;
    padding: 17px 32px;
    width: fit-content;
    text-decoration: none;
    font-weight: 500;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  color: ${COLORS.WHITE};

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 18px;
  }
`;

export const RightSide = styled.div`
  box-sizing: border-box;
  background: ${COLORS.BLUE};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 52px;
  position: relative;
  padding-block: 50px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding-top: 30px;
    padding-bottom: 20px;
    width: auto;
    padding-inline: 24px;

    flex-grow: 1;
  }
`;

export const LeftSide = styled.div`
  background: ${COLORS.BLUE};
  position: relative;
  overflow: hidden;
  z-index: 10;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    max-height: 40vh;
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 2;
    background: linear-gradient(black, transparent 25%);
    opacity: 0.25;
  }
`;

export const GlobalContainer = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  min-height: 100vh;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

export const BackgroundRectangle = styled.div`
  background: ${(props) => (props.side === 'left' ? COLORS.YELLOW : COLORS.RED)};
  width: 100%;
  height: 100%;
  position: absolute;
  left: ${(props) => (props.side === 'left' ? '5px' : '120px')};
  ${(props) => {
    if (props.side === 'left') {
      return `top: 5px;`;
    } else {
      return `bottom: 8px;`;
    }
  }}

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    left: ${(props) => props.side === 'right' && '80px'};
  }
`;

export const ImageNetwork = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

export const AdvantageContainer = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 20px;
  padding-block: 20px;
  padding-inline: 30px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 18px;
  }
`;

export const AdvantageList = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
`;

export const AdvantageItem = styled.li`
  list-style: none;
  position: relative;
  padding-left: 16px;

  &::after {
    content: '';
    background-image: url('/images/bullet-point.svg');
    position: absolute;
    top: 8px;
    left: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 8px;
    height: 8px;
  }
`;

export const HighlightedWord = styled.span`
  color: ${COLORS.BLUE};
  font-weight: 700;
`;

export const ImageLines = styled.img`
  position: absolute;
  width: 200px;
  height: auto;
  transform: scaleX(-1);
  top: -80px;
  right: -140px;

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 165px;
    top: -65px;
    right: -110px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 120px;
    top: -40px;
    right: -90px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    top: -50px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    display: none;
  }
`;

export const PolicyText = styled.p`
  margin: 0;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: ${COLORS.WHITE};
  max-width: 658px;
  padding-top: 32px;
  padding-bottom: 37px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    text-align: center;
  }
`;

export const BackgroundImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
`;
