import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';
import { Link } from 'gatsby';

export const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    height: auto;
  }
`;

export const ContentContainer = styled.div`
  padding-inline: 24px;

  @media (max-width: ${BREAKPOINTS.XL_DESKTOP}px) {
    padding-block: 24px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-block: 50px;
  }
`;

export const TitleContainer = styled.div`
  color: ${COLORS.DARK_GREY};
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    row-gap: unset;
  }
`;

export const QuestionTitle = styled.h2`
  font-size: 12px;
  font-weight: 400;
  opacity: 0.9;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    max-width: 24ch;
    text-align: center;
  }
`;

export const SendButton = styled.button`
  padding: 17px 32px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${(props) => (props.disabled ? COLORS.LIGHT_GREY : COLORS.BLUE)};
  color: ${(props) => (props.disabled ? COLORS.DARK_GREY : COLORS.WHITE)};
  border-radius: 999px;
  background: ${(props) => (props.disabled ? COLORS.LIGHT_GREY : COLORS.BLUE)};
  text-decoration: none;
`;

export const Information = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto;
  padding-bottom: 24px;
  color: ${COLORS.DARK_GREY};
  max-width: 518px;
  text-align: center;
  opacity: 0.9;
`;

export const Email = styled(Link)`
  color: ${COLORS.DARK_GREY};
`;

export const ButtonContainer = styled.div`
  padding-bottom: 50px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    position: fixed;
    bottom: 0;
    padding-block: 12px;
    box-shadow: 0px 0px 90px 0px ${COLORS.BLACK}33;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${COLORS.WHITE};
    z-index: 20;
  }
`;
