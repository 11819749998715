import styled from 'styled-components';

import { BREAKPOINTS } from '../../../utils/theme';

export const ImageCloud = styled.img`
  position: absolute;
  z-index: -1;
  top: 50px;
  right: -230px;
  width: 470px;
  height: auto;

  display: ${(props) => (props.step > 2 ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 420px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 370px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    top: auto;
    bottom: -120px;
    right: -150px;
    width: 300px;
    opacity: 0.5;
  }
`;

export const ImageSquare = styled.img`
  position: absolute;
  right: -230px;
  bottom: 30px;
  width: 650px;
  height: auto;
  z-index: -2;

  display: ${(props) => (props.step > 0 ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 550px;
    right: -270px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 550px;
    right: -400px;
    bottom: 90px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    right: auto;
    left: -170px;
    top: -170px;
    width: 350px;
    transform: rotate(-45deg);
  }
`;

export const ImageDotted = styled.img`
  position: absolute;
  top: -280px;
  left: -50px;
  width: 480px;
  height: auto;
  z-index: -3;

  display: ${(props) => (props.step > 0 ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 440px;
    top: -200px;
    left: -110px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    left: auto;
    right: -200px;
    transform: scaleY(-1) rotate(20deg);
    top: -200px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    left: auto;
    right: -250px;
    transform: scaleY(-1) rotate(20deg);
    top: -250px;
  }
`;

export const ImageOrange = styled.img`
  position: absolute;
  top: 100px;
  z-index: -2;
  left: -130px;
  width: 470px;
  height: auto;

  display: ${(props) => (props.step > 1 ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 340px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 320px;
    left: -250px;
    top: 220px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    top: -120px;
    left: 100px;
    width: 170px;
    z-index: 3;
  }
`;

export const ImageYellow = styled.img`
  position: absolute;
  z-index: -1;
  transform: scaleX(-1) rotate(15deg);
  left: -180px;
  bottom: -50px;
  width: 600px;
  height: auto;

  display: ${(props) => (props.step > 2 ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 520px;
    left: -240px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 450px;
    left: -280px;
    bottom: 0px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    bottom: -80px;
    left: -180px;
    width: 320px;
    opacity: 0.5;
  }
`;
