import React from 'react';

import StepInputRadio from '../StepInputRadio';

import * as Styled from './styles';

const ThirdStep = ({ register, errors, step, artsAreOptions, watch }) => {
  const id = 'artsAre';
  const selectedValue = watch(id);

  return (
    <Styled.GlobalContainer>
      {/* <Styled.TitleContainer>
        <Styled.QuestionTitle>Select the option that best describes you.</Styled.QuestionTitle>
      </Styled.TitleContainer> */}

      <Styled.ContentContainer
        style={{
          paddingTop: '50px',
        }}
      >
        <div
          style={{
            paddingBottom: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '30px',
          }}
        >
          <StepInputRadio
            label="Select the option that best describes you"
            id="artsAre"
            register={register}
            watch={watch}
            step={step}
            sm={true}
            options={artsAreOptions?.map((el) => ({
              label: el?.attributes?.name,
              id: el?.id,
              description: el?.attributes?.description,
            }))}
            errors={errors}
            inputType="check"
          ></StepInputRadio>
        </div>

        <Styled.Information>
          Let us know if you identify beyond these descriptions. Have something to say? We’d love to
          hear it! Email us at{' '}
          <Styled.Email to="mailto:info@community-arts.net">info@community-arts.net</Styled.Email>
        </Styled.Information>
      </Styled.ContentContainer>
      <Styled.ButtonContainer>
        <Styled.SendButton disabled={!selectedValue}>Next</Styled.SendButton>
      </Styled.ButtonContainer>
    </Styled.GlobalContainer>
  );
};

export default ThirdStep;
