import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { navigate } from '@reach/router';
import { API_HOSTNAME } from '../../utils/urls';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import loadable from '@loadable/component';
import { useMedia, usePrevious } from 'react-use';

import { BREAKPOINTS } from '../../utils/theme';
import { WELCOME_FINAL_OPTIONS, KEY_WELCOME_TEMPORARY_DATA } from '../../utils/constants';
import { removeAllTemporaryData, getWelcomeTemporaryData } from '../../utils/local-storage';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import WelcomeImages from './WelcomeImages';

import SEO from '../SEO';
import { Logo, MenuButton } from '../HomeHeader/styles';

const Header = loadable(() => import('../Header'));
const CookieBanner = loadable(() => import('../Footer/CookieBanner'));

const fetchData = async (url) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  try {
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message + ' ' + url || 'Network response was not OK');
    }
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const saveTemporaryData = (data) => {
  localStorage.setItem(KEY_WELCOME_TEMPORARY_DATA, JSON.stringify(data));
};

const Welcome = ({ step }) => {
  const [menuIsShown, showMenu] = React.useState(false);

  const isLargerThanTablet = useMedia(`(min-width: ${BREAKPOINTS.TABLET}px)`);

  const STEPS_COUNT = 4;

  const getStepSubmitType = () => {
    if (parseInt(step) < STEPS_COUNT - 1) return 'continue';
    return 'none';
  };

  /**
   * @type {'none' | 'continue'}
   */
  const stepSubmitType = getStepSubmitType();

  const validationStepsSchema = [
    //validation for step1
    yup.object().shape({
      typeOfMember: yup.string().required(),
      operatingIn: yup.string().nullable(),
    }),
    //validation for step2
    yup.object().shape({
      artsAre: yup.string().nullable(),
    }),
  ];

  const currentValidationSchema = validationStepsSchema?.[parseInt(step)] || yup.object();

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    defaultValues: getWelcomeTemporaryData(),
  });

  const [typeOfMemberOptions, setTypeOfMemberOptions] = useState(null);
  const [operatingInOptions, setOperatingInOptions] = useState(null);
  const [artsAreOptions, setArtsAreOptions] = useState(null);

  const { register, handleSubmit, watch } = methods;

  const artsAreAnswer = methods.watch('artsAre');
  const operatingInAnswer = methods.watch('operatingIn');
  const userOperatesInArtsSector = operatingInAnswer && parseInt(operatingInAnswer) === 1;

  const selectedTypeOfMemberId = methods.watch('typeOfMember');
  const prevTypeOfMemberId = usePrevious(selectedTypeOfMemberId);

  useEffect(() => {
    if (prevTypeOfMemberId && prevTypeOfMemberId !== selectedTypeOfMemberId) {
      removeAllTemporaryData();
      methods.reset({
        typeOfMember: selectedTypeOfMemberId,
      });
    }
  }, [selectedTypeOfMemberId]);

  const getFinalStep = () => {
    if (userOperatesInArtsSector) {
      return WELCOME_FINAL_OPTIONS.ACTOR;
    }
    switch (parseInt(artsAreAnswer)) {
      case 1:
      case 2:
        return WELCOME_FINAL_OPTIONS.ACTOR;
      case 3:
        return WELCOME_FINAL_OPTIONS.BELIEVER;
      default:
        return WELCOME_FINAL_OPTIONS.CURIOUS;
    }
  };

  const finalStep = getFinalStep();

  useEffect(() => {
    fetchData(`${API_HOSTNAME}/user-survey-type-of-members`).then((res) => {
      setTypeOfMemberOptions(res?.data);
    });
    fetchData(`${API_HOSTNAME}/User-survey-operating-ins`).then((res) => {
      setOperatingInOptions(res?.data);
    });
    fetchData(`${API_HOSTNAME}/user-survey-arts-ares?sort=slug:ASC`).then((res) => {
      setArtsAreOptions(res?.data);
    });
  }, []);

  const onSubmit = (data) => {
    if (stepSubmitType === 'continue') {
      saveTemporaryData(methods.getValues());

      if (userOperatesInArtsSector) {
        // Go to registration directly
        navigate(`/welcome/${STEPS_COUNT - 1}`);
      } else {
        navigate(`/welcome/${parseInt(step) + 1}`);
      }
    }
  };

  return (
    <>
      <SEO title="Welcome to CAN"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} scrolled />
      <Logo
        visible={true}
        white={isLargerThanTablet ? true : parseInt(step) === 0 || parseInt(step) === 3}
        fixedBackgroundAttachment={false}
      />
      <MenuButton
        visible={true}
        menuIsShown={menuIsShown}
        white={isLargerThanTablet ? false : parseInt(step) === 0}
        fixedBackgroundAttachment={false}
      />
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        {parseInt(step) === 0 && <FirstStep />}
        {parseInt(step) > 0 && parseInt(step) < 3 && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {parseInt(step) === 1 ? (
                <SecondStep
                  register={register}
                  watch={watch}
                  errors={methods.formState.errors}
                  typeOfMemberOptions={typeOfMemberOptions}
                  operatingInOptions={operatingInOptions}
                />
              ) : parseInt(step) === 2 ? (
                <ThirdStep
                  register={register}
                  watch={watch}
                  errors={methods.formState.errors}
                  artsAreOptions={artsAreOptions}
                  step={step}
                />
              ) : null}
            </form>
          </FormProvider>
        )}

        {parseInt(step) === 3 && <FourthStep finalStep={finalStep} />}

        <WelcomeImages step={parseInt(step)}></WelcomeImages>

        <CookieBanner />
      </div>
    </>
  );
};

export default Welcome;
