import React from 'react';

import * as Styled from './styles';
import { COLORS } from '../../../utils/theme';
import { Link } from 'gatsby';

const BeginRegistration = () => {
  return (
    <>
      <Styled.GlobalContainer>
        <Styled.LeftSide>
          <Styled.BackgroundImage
            src="/images/begin-registration.jpg"
            alt=""
            width="2000"
            height="1999"
            loading="lazy"
            decoding="async"
          ></Styled.BackgroundImage>
        </Styled.LeftSide>
        <Styled.RightSide>
          <div style={{ width: 'fit-content', position: 'relative', zIndex: '2' }}>
            <Styled.TitleContainer>
              <Styled.Title>Begin registration!</Styled.Title>
              <Styled.ImageLines
                src="/images/lines.png"
                width="509"
                height="526"
                loading="lazy"
                decoding="async"
                alt=""
              />
            </Styled.TitleContainer>

            <Styled.Text style={{ paddingBottom: '36px', maxWidth: '513px' }}>
              As a member, your work will reach a growing global network of artistic changemakers,
              and beyond. Register to join!
            </Styled.Text>

            <Styled.AdvantageContainer>
              <p style={{ margin: 0 }}>Registered CAN members are able to:</p>
              <Styled.AdvantageList>
                <Styled.AdvantageItem>
                  <Styled.HighlightedWord>Promote</Styled.HighlightedWord> themselves and their work
                  on the CAN network and channels
                </Styled.AdvantageItem>
                <Styled.AdvantageItem>
                  <Styled.HighlightedWord>Explore</Styled.HighlightedWord> and{' '}
                  <Styled.HighlightedWord>access</Styled.HighlightedWord> the network, using our{' '}
                  <a href="/network-map" style={{ color: COLORS.BLACK }} target="_blank">
                    Matchmaker & Network Map tools
                  </a>
                </Styled.AdvantageItem>
                <Styled.AdvantageItem>
                  <Styled.HighlightedWord>Connect</Styled.HighlightedWord> directly with a variety
                  of artists, organisations, artivists, funders and others linked to arts for social
                  impact
                </Styled.AdvantageItem>
              </Styled.AdvantageList>
              <p style={{ margin: 0, color: COLORS.DARK_GREY, fontSize: '16px' }}>
                You can learn more about CAN and being a member{' '}
                <a href="/Discover_CAN.pdf" target="_blank" style={{ color: COLORS.DARK_GREY }}>
                  here
                </a>
              </p>
            </Styled.AdvantageContainer>
            <Styled.PolicyText>
              Your information will be shared with CAN members directly and publicly after
              registering as a member of the network. You will be able to review your profile and
              make any required changes before it is published at the end of the registration
              process. Further information can be found{' '}
              <a href="/privacy-policy" target="_blank" style={{ color: COLORS.WHITE }}>
                here
              </a>
            </Styled.PolicyText>

            <Styled.ButtonsContainer>
              <Link to="/profile" aria-label="Registration">
                Start registration
              </Link>
              <div style={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  fill="none"
                  viewBox="0 0 13 13"
                >
                  <path
                    fill="#FFFBF7"
                    d="M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Zm0 12a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11Zm4-5.5a.5.5 0 0 1-.5.5H6.5a.5.5 0 0 1-.5-.5V3a.5.5 0 1 1 1 0v3h3a.5.5 0 0 1 .5.5Z"
                  />
                </svg>
                <p>Average time 10-15 min</p>
              </div>
            </Styled.ButtonsContainer>
          </div>
        </Styled.RightSide>
      </Styled.GlobalContainer>
    </>
  );
};

export default BeginRegistration;
