import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils/theme';

export const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 0;
  margin-block: 24px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Legend = styled.legend`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2%;
  padding-bottom: 10px;
`;

export const SecondaryTitle = styled.h2`
  margin: 0;
  padding-bottom: 30px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`;

export const LeftLines = styled.img`
  position: absolute;
  left: -50px;
  top: -30px;
  width: 173px;
  height: 201px;
  transform: rotate(45deg);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    left: -40px;
    top: 0px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    bottom: 90px;
    top: auto;
    left: -60px;
    width: 123px;
    height: auto;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    top: -40px;
    bottom: 60px;
    left: -30px;
    width: 103px;
    height: auto;
  }
`;
export const RightLines = styled.img`
  position: absolute;
  right: -80px;
  bottom: -40px;
  width: 173px;
  height: 201px;
  transform: rotate(-135deg);

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    right: -50px;
    bottom: -20px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    bottom: auto;
    top: 100px;
    right: -60px;
    width: 123px;
    height: auto;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    bottom: auto;
    top: 130px;
    right: -30px;
    width: 103px;
    height: auto;
  }
`;

export const SuccessTitle = styled.p`
  margin: 0;
  font-size: 50px;
  line-height: 56px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 36px;
    line-height: normal;
  }
`;

export const SuccessDescription = styled.p`
  padding-top: 10px;
  font-size: 22px;
  line-height: 32px;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 18px;
    line-height: normal;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-bottom: 20px;
  }
`;
