import { URL_REGEX_PATTERN } from './regex';

export const API_HOSTNAME = process.env.GATSBY_API_URL;

export const buildAPIURL = (path, query = '') => {
  try {
    if (path[0] !== '/') throw new Error('The pathname must start with /');
    let queryConnector = '';
    if (query) {
      if (path.includes('?')) {
        queryConnector = '&';
      } else {
        queryConnector = '?';
      }
    }
    const pathname = `${path}${queryConnector}${query}`;
    return API_HOSTNAME + pathname;
  } catch (e) {
    console.warn(`Unable to build the URL, invalid pathname -> ${path}`);
    console.error(`Generated error: ${e}`);
  }
};

export const addUrlProtocolIfMissing = (url, debug) => {
  const isValidUrl = URL_REGEX_PATTERN.test(url);

  if (isValidUrl && !url.startsWith('http://') && !url.startsWith('https://')) {
    if (debug) console.log({ url });
    return `https://${url}`;
  }

  return url;
};
