import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';
import { WELCOME_FINAL_OPTIONS } from '../../../utils/constants';
import { Link } from 'gatsby';

export const Title = styled.h1`
  font-size: 50px;
  line-height: 56px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  position: relative;
  z-index: 10;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 40px;
    line-height: 44px;
    font-weight: 500;

    ${(props) => {
      if (props.finalStep === WELCOME_FINAL_OPTIONS.ACTOR) {
        return `
        max-width: 10ch; 
        margin: 0 auto;
        `;
      }
    }}
  }
`;

export const DescriptionContainer = styled.div`
  max-width: 50ch;
  margin: 0 auto;
  color: ${COLORS.DARK_GREY};
  padding-inline: 24px;
  opacity: 0.9;

  font-size: 16px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 18px;
  }

  & > p + p {
    margin-top: 20px;
  }
`;

export const Description = styled.p`
  padding: 0;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    text-align: left;
    margin-inline: 24px;
  }

  & span {
    font-weight: 700;
    color: ${COLORS.BLACK};
  }
`;

export const ListOptions = styled.ul`
  list-style: none;
  padding-block: 40px;
  padding-inline: 0;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    text-align: left;
    margin-inline: 24px;
  }
`;

export const Option = styled.li`
  display: flex;
  column-gap: 14px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.BLACK};

  border-bottom: 1px solid ${COLORS.LIGHT_GREY};
  padding-block: 19px;

  &:first-child {
    border-top: 1px solid ${COLORS.LIGHT_GREY};
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    align-items: center;
    justify-content: flex-start;
    padding-block: 12px;
    padding-left: 22px;
  }
`;

export const GlobalContainer = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.finalStep === WELCOME_FINAL_OPTIONS.BELIEVER ? 'space-between' : 'center'};
  align-items: center;
  min-height: 100vh;
  position: relative;

  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    ${(props) => {
      if (props.finalStep === WELCOME_FINAL_OPTIONS.BELIEVER) {
        return `height: auto;`;
      } else {
        return `min-height: 100vh;`;
      }
    }}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-bottom: ${(props) =>
      props.finalStep === WELCOME_FINAL_OPTIONS.ACTOR ? 'unset' : '30px'};
  }
`;

export const ExternalLink = styled.a`
  padding: 17px 32px;
  display: block;
  color: ${COLORS.WHITE};
  background: ${COLORS.BLUE};
  border: none;
  border-radius: 999px;
  width: fit-content;
  margin: 0 auto;
  text-decoration: none;
`;

export const InternalLink = styled(Link)`
  padding: 17px 32px;
  display: block;
  color: ${COLORS.WHITE};
  background: ${COLORS.BLUE};
  border: none;
  border-radius: 999px;
  width: fit-content;
  margin: 0 auto;
  text-decoration: none;
`;

export const LinkContainer = styled.div`
  ${(props) => {
    if (props.finalStep !== WELCOME_FINAL_OPTIONS.ACTOR) {
      return `margin-top: 40px;`;
    }
  }}

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    position: fixed;
    bottom: 0;
    padding-block: 12px;
    box-shadow: 0px 0px 90px 0px ${COLORS.BLACK}33;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${COLORS.WHITE};
    z-index: 30;
  }
`;

export const LeftLines = styled.img`
  position: absolute;
  left: -70px;
  bottom: -40px;
  width: 173px;
  height: 201px;
  transform: rotate(-20deg);

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    left: -40px;
    bottom: -30px;
  }
`;

export const RightLines = styled.img`
  position: absolute;
  right: -60px;
  bottom: -30px;
  width: 173px;
  height: 201px;
  transform: rotate(160deg);

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    right: -20px;
    bottom: -20px;
  }
`;

export const GreetingLine = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  width: 277px;
  height: auto;
`;

export const OptionText = styled.span`
  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    display: inline-block;
    max-width: 26ch;
  }
`;

export const LegalInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0 auto;
  margin-top: 40px;
  opacity: 0.9;
  color: ${COLORS.DARK_GREY};
  text-align: center;
  max-width: 424px;
  padding-bottom: 24px;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    font-size: 12px;
    padding-inline: 24px;
    margin-top: 0;
  }
`;

export const PrivacyPolicyLink = styled.a`
  color: ${COLORS.DARK_GREY};
`;

export const TitleContainer = styled.div`
  position: relative;
  padding-top: ${(props) => (props.finalStep === WELCOME_FINAL_OPTIONS.ACTOR ? '40px' : '100px')};
  padding-bottom: 40px;
`;

export const MainContainer = styled.div`
  padding-bottom: 30px;
`;

export const BelieversContainer = styled.div`
  display: flex;
  column-gap: 36px;
  padding-top: 80px;
  padding-inline: 40px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
    row-gap: 36px;
    padding-top: 50px;
  }
`;

export const LeftContainer = styled.div`
  background-color: ${COLORS.LIGHT_GREY};
  padding: 34px;
  border-radius: 8px;
  text-align: left;
  width: 50%;
  height: fit-content;
  max-width: 592px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: auto;
  }
`;
export const SupportListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;

  & p:first-child {
    color: ${COLORS.BLACK};
    margin: 0;
    padding-bottom: 3px;

    & span {
      font-weight: 700;
    }
  }

  & p:last-child {
    color: ${COLORS.DARK_GREY};
    margin: 0;

    & a {
      color: ${COLORS.BLUE};
    }
  }

  &::after {
    content: '';
    background-image: url('/images/bullet-point.svg');
    position: absolute;
    top: 8px;
    left: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 8px;
    height: 8px;
  }
`;
export const RightContainer = styled.div`
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  padding: 34px;
  border-radius: 8px;
  text-align: left;
  width: 50%;
  max-width: 592px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: auto;
  }
`;

export const SecondaryTitle = styled.h2`
  margin: 0;
  padding-bottom: 30px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`;
