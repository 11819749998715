import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Legend = styled.legend`
  font-size: 40px;
  font-weight: 400;
  line-height: 34px;
  color: ${COLORS.BLACK};
  text-align: center;
  padding-bottom: 20px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 8px;
  }
`;

export const UnderlinedRadioType = styled.input`
  & ~ label {
    border-style: solid;
    border-bottom-width: 5px;
    color: ${COLORS.DARK_GREY};
    font-size: 34px;
    line-height: 40px;
    border-image: url('/images/trace-gray.svg') 0 0 20 0 stretch stretch;

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    & ~ label {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &:checked ~ label {
    color: ${COLORS.BLUE};
    border-image: url('/images/trace-blue.svg') 0 0 20 0 stretch stretch;
  }

  &:focus-visible ~ label {
    outline: 3px solid ${COLORS.BLUE};
    outline-offset: 1px;
  }
`;

export const CheckboxRadioType = styled.input`
  & ~ label {
    color: ${COLORS.BLACK};
    font-size: 24px;
    line-height: 32px;

    &:hover {
      cursor: pointer;
    }
  }

  & ~ label:after {
    content: '';
    position: absolute;
    inset: 0px;
    z-index: 5;
  }

  & ~ p {
    color: ${COLORS.DARK_GREY};
    margin: 0;
    padding-bottom: 24px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    & ~ label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }

    & ~ p {
      opacity: 0.9;
    }
  }

  &:checked ~ label {
    color: ${COLORS.BLUE};
  }

  &:checked ~ p {
    color: ${COLORS.BLUE};
  }

  &:focus-visible ~ label {
    outline: 3px solid ${COLORS.BLUE};
    outline-offset: 1px;
  }
`;

export const AnswersContainer = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: center;
  padding-top: 10px;
  flex-direction: ${(props) => (props.inputType === 'underlined' ? 'row' : 'column')};

  ${(props) => {
    if (props.inputType === 'check') {
      return `
      & > * + * {
        margin-top: 24px;
      }

      @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      & > * + * {
        margin-top: 0px;
      }
      }
      `;
    }
  }}

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const RadioButtonContainer = styled.div`
  position: relative;

  ${(props) => {
    if (props.inputType === 'check') {
      return `
      border-style: solid;
      border-bottom-width: 5px;
      border-image: url('/images/trace-gray.svg') 0 0 20 0 stretch stretch;
      display: flex;
      justify-content: space-between;
      align-items: center;
      `;
    }
  }}
`;

export const CheckboxMark = styled.div`
  ${(props) => {
    if (props.isActive) {
      return `background-image: url('/images/checkbox-circle-black.png');`;
    } else {
      return `background-image: url('/images/checkbox-circle.png');`;
    }
  }}

  margin-Left: 140px;

  width: 50px;
  height: 45px;
  position: relative;
  right: 10px;

  transform: translateY(-25%);

  background-size: cover;
  background-position: center;

  pointer-events: none;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    margin-left: 20px;
  }
`;

export const Checkmark = styled.img`
  width: 70px;
  position: absolute;
  right: -12px;
  bottom: 0px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 60px;
    position: absolute;
    right: -7px;
    bottom: 2px;
  }
`;

export const Line = styled.hr`
  margin: 0;
  padding: 0;
  border-style: solid;
  border-bottom-width: 5px;
  border-image: url('/images/trace-gray.svg') 0 0 20 0 stretch stretch;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 500;
  max-width: 430px;
  pointer-events: none;
  line-height: 20px;
`;

export const InputLabel = styled.label`
  padding-bottom: ${(props) => (props.sm ? '24px' : '5px')};

  ${(props) => {
    if (props.sm) {
      return `
      font-size: 20px;
      display: inline-block;
      max-width: 35ch;
      line-height: 1.25;
      `;
    }
  }}
`;
