import React from 'react';

import * as Styled from './styles';

const FirstStep = () => {
  return (
    <Styled.GlobalContainer>
      <Styled.LeftSide>
        <Styled.MainTitle>Welcome to CAN - The Community Arts Network!</Styled.MainTitle>

        <Styled.FirstWelcomeImageContainer>
          <img
            src="/images/welcome-1.jpg"
            alt=""
            width="1000"
            height="667"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              position: 'relative',
              zIndex: '2',
            }}
          ></img>
          <Styled.BackgroundRectangle side="left"></Styled.BackgroundRectangle>
        </Styled.FirstWelcomeImageContainer>
        <Styled.SecondWelcomeImageContainer>
          <img
            src="/images/welcome-2.jpg"
            alt=""
            width="1000"
            height="667"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              position: 'relative',
              zIndex: '2',
            }}
          ></img>
          <Styled.BackgroundRectangle side="right"></Styled.BackgroundRectangle>
        </Styled.SecondWelcomeImageContainer>

        <Styled.ImageDotted
          src="/images/dotted-blue.png"
          alt=""
          width="917"
          height="1074"
        ></Styled.ImageDotted>

        <Styled.ImageSquare
          src="/images/square-blue.png"
          alt=""
          width="1274"
          height="1156"
        ></Styled.ImageSquare>
      </Styled.LeftSide>
      <Styled.RightSide>
        <Styled.ImageNetwork src="/images/network.svg" alt="" />
        <div style={{ width: 'fit-content', position: 'relative', zIndex: '2' }}>
          <Styled.SecondaryTitle>Join CAN!</Styled.SecondaryTitle>
          <Styled.TextContainer>
            <Styled.Text>
              We're a global platform that enables, engages, and empowers individuals, organisations
              and communities using arts for social change, to shape a humane future, together.
            </Styled.Text>
            <Styled.Text>
              Are you an individual or organisation that uses arts for social impact or that is
              interested in engaging the arts in your change-making projects? Or simply curious
              about arts for social impact and engaging with us? We would love to explore how you
              can connect with the network!
            </Styled.Text>
          </Styled.TextContainer>

          <Styled.ButtonsContainer>
            <Styled.InternalLink to="/welcome/1" aria-label="Welcome">
              Explore becoming part of CAN
            </Styled.InternalLink>
            <Styled.ExternalLink href="/network-map" aria-label="Network map" target="_blank">
              Explore the Network
            </Styled.ExternalLink>
          </Styled.ButtonsContainer>
        </div>
      </Styled.RightSide>
    </Styled.GlobalContainer>
  );
};

export default FirstStep;
