import React from 'react';

import * as Styled from './styles';

const WelcomeImages = ({ step }) => {
  return (
    <>
      <Styled.ImageCloud
        step={step}
        src="/images/cloud-blue.png"
        alt=""
        width="928"
        height="992"
      ></Styled.ImageCloud>
      <Styled.ImageDotted
        step={step}
        src="/images/dotted-blue.png"
        alt=""
        width="917"
        height="1074"
      ></Styled.ImageDotted>
      <Styled.ImageOrange
        step={step}
        src="/images/orange.png"
        alt=""
        width="1013"
        height="1070"
      ></Styled.ImageOrange>
      <Styled.ImageYellow
        step={step}
        src="/images/yellow.png"
        alt=""
        width="1342"
        height="1285"
      ></Styled.ImageYellow>
      <Styled.ImageSquare
        step={step}
        src="/images/square-blue.png"
        alt=""
        width="1274"
        height="1156"
      ></Styled.ImageSquare>
    </>
  );
};

export default WelcomeImages;
