import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { API_HOSTNAME } from '../../../utils/urls';
import StepInputText from '../../NewRegisterForm/StepInputText';
import StepInputTextarea from '../../NewRegisterForm/StepInputTextarea';
import { NewButton } from '../../NewButton';
import { COLORS } from '../../../utils/theme';
import * as Styled from './styles';
import { postData } from '../../../utils/api';
import { DEFAULT_INPUT_MAX_LENGTH, LONG_INPUT_MAX_LENGTH } from '../../../utils/constants';

const BelieversForm = () => {
  const [state, setState] = useState('idle'); // idle | loading | success | error
  const [error, setError] = useState(null);

  const validationStepsSchema = yup.object().shape({
    fullName: yup.string().required(),
    email: yup.string().email().required(),
    summary: yup.string().max(LONG_INPUT_MAX_LENGTH),
    workLink: yup.string().nullable().max(DEFAULT_INPUT_MAX_LENGTH),
  });

  const currentValidationSchema = validationStepsSchema || yup.object();

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
  });

  const onSubmit = async () => {
    if (!Object.keys(methods.formState.errors).length) {
      setState('loading');
      const data = methods.getValues();

      const newData = {
        fullName: data?.fullName,
        email: data?.email,
        summary: data?.summary,
        workLink: data?.workLink,
      };

      await postData(`${API_HOSTNAME}/believers`, {
        data: {
          ...newData,
        },
      })
        .then(() => {
          setState('success');
        })
        .catch((err) => {
          setError(err?.message);
          setState('error');
        });
    }
  };

  return (
    <>
      {state === 'success' ? (
        <>
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100% ',
              maxWidth: '291px',
              margin: '0 auto',
            }}
          >
            <Styled.LeftLines src="/images/lines.png" alt="" width="509" height="526" />

            <div>
              <Styled.SuccessTitle>Thanks!</Styled.SuccessTitle>
              <Styled.SuccessDescription>
                <strong style={{ color: `${COLORS.WHITE}` }}>Please check your inbox & spam</strong>{' '}
                for a confirmation email containing a verification link. <br />
                <strong style={{ color: `${COLORS.WHITE}` }}>Click the verification link</strong> to
                complete your submission.
              </Styled.SuccessDescription>
            </div>
            <Styled.RightLines src="/images/lines.png" alt="" width="509" height="526" />
          </div>
        </>
      ) : (
        <>
          <Styled.SecondaryTitle>To share more, get in touch!</Styled.SecondaryTitle>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Styled.Fieldset>
                <Styled.Legend>
                  1. Your name or name of your organisation (if applicable)
                </Styled.Legend>
                <StepInputText
                  maxWidthType={'auto'}
                  textLight
                  id="fullName"
                  autocomplete="off"
                  register={methods.register}
                  placeholder="Full name"
                  errors={methods.formState.errors}
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                />
              </Styled.Fieldset>

              <Styled.Fieldset>
                <Styled.Legend>2. Your email adress</Styled.Legend>
                <StepInputText
                  maxWidthType={'auto'}
                  textLight
                  id="email"
                  autocomplete="off"
                  register={methods.register}
                  placeholder="hello@example.com"
                  errors={methods.formState.errors}
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                />
              </Styled.Fieldset>

              <Styled.Fieldset>
                <Styled.Legend>3. A short description of your work</Styled.Legend>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    margin: 0,
                    paddingTop: '6px',
                    paddingBottom: '24px',
                  }}
                >
                  Feel free to mention if you’ve had experience with community arts or arts for
                  social change or if you envisage integrating it into your work; or share
                  challenges you might have had in trying to integrate it.
                </p>

                <StepInputTextarea
                  maxWidthType="auto"
                  textLight
                  id="summary"
                  register={methods.register}
                  watch={methods.watch}
                  placeholder="Your description here"
                  errors={methods.formState.errors}
                />
              </Styled.Fieldset>

              <Styled.Fieldset>
                <Styled.Legend>
                  4. A link to your work (online, social media, website, etc.)
                </Styled.Legend>
                <StepInputText
                  maxWidthType={'auto'}
                  textLight
                  id="workLink"
                  autocomplete="off"
                  register={methods.register}
                  placeholder="Link to your work"
                  errors={methods.formState.errors}
                />
              </Styled.Fieldset>

              <p
                style={{
                  lineHeight: '20px',
                  fontSize: '14px',
                  maxWidth: '518px',
                  textAlign: 'center',
                  paddingTop: '40px',
                  paddingBottom: '30px',
                  margin: '0 auto',
                }}
              >
                This information will not be published, it is for our internal records. Please, read
                our{' '}
                <a href="/privacy-policy/" target="_blank" style={{ color: COLORS.WHITE }}>
                  Privacy Policy
                </a>{' '}
                to learn more.
              </p>

              <NewButton
                type="submit"
                variant="tertiary"
                disabled={state === 'loading'}
                style={{
                  width: 'fit-content',
                  margin: '0 auto',
                  opacity: state === 'loading' ? 0.5 : 1,
                }}
              >
                {state === 'idle' || state === 'error' ? 'Send' : 'Sending'}
              </NewButton>

              {state === 'error' && (
                <div
                  style={{
                    background: COLORS.RED,
                    width: 'fit-content',
                    paddingInline: '10px',
                    paddingBlock: '4px',
                    borderRadius: '6px',
                    marginTop: '20px',
                    marginInline: 'auto',
                  }}
                >
                  <p
                    style={{ margin: 0, fontSize: '14px', color: COLORS.WHITE, lineHeight: '20px' }}
                  >
                    {error}
                  </p>
                </div>
              )}
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default BelieversForm;
