import * as React from 'react';
import { Router } from '@reach/router';

import Welcome from '../components/Welcome/view';

const WelcomeSteps = () => {
  return (
    <div>
      <Router>
        <Welcome path="/welcome/:step" />
      </Router>
    </div>
  );
};

export default WelcomeSteps;
