import styled from 'styled-components';

import SmallLogoWhiteImg from '../images/logo-small-white.png';
import MenuButtonWhiteImg from '../images/menu-white.svg';
import SmallLogoBlack from '../images/logo-small.png';
import MenuButtonBlackImg from '../images/menu-black.svg';

export const COLORS = {
  WHITE: '#FFFBF7',
  BLACK: '#2B2B2B',
  LIGHT_GREY: '#F3EFEC',
  DARK_GREY: '#6B6865',
  BLUE: '#5763FF',
  PURPLE: '#835AEB',
  RED: '#F06439',
  LIGHT_RED: '#F03961',
  GREEN: '#8CA17D',
  PINK: '#F9AE9E',
  YELLOW: '#FFD100',
  BACKGROUND_LIGHT: '#FFFBF7',
  BACKGROUND_LIGHT_2: '#f7f3ef',
};

export const BREAKPOINTS = {
  MOBILE: 481,
  PHABLET: 761,
  TABLET: 1025,
  DESKTOP: 1281,
  XL_DESKTOP: 1601,
};

export const SCROLL_WIDTH = 15;

// const setLetterSpacing = (characterSpacing, textSize) => characterSpacing / textSize

export const HudgeTitle = styled.h1`
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 158px;
  font-weight: 600;
  line-height: 144px;
  letter-spacing: -1.64px;
`;

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  color: ${COLORS.BLACK};
  font-size: 72px;
  font-weight: normal;
`;

export const H2 = styled.h2`
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 50px;
  font-weight: normal;
`;

export const H3 = styled.h3`
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 34px;
  font-weight: normal;
`;

export const H4 = styled.h4`
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const H5 = styled.h5`
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 16px;
  font-weight: 500;
`;

export const H6 = styled.h6`
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 14px;
  font-weight: 600;
`;

export const P = styled.p`
  padding: 0;
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 20px;
  font-weight: normal;
  opacity: 0.9;
  line-height: 32px;
`;

export const Ps = styled.p`
  padding: 0;
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 16px;
  font-weight: normal;
  opacity: 0.9;
`;

export const Pb = styled.p`
  padding: 0;
  margin: 0;
  color: ${COLORS.BLACK};
  font-size: 28px;
  font-weight: normal;
  opacity: 0.9;
  line-height: 40px;
`;

export const Logo = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: 60px 38px;
  background-attachment: fixed;
  position: absolute;
  z-index: 999;
  pointer-events: none;
  top: 0;
  left: 0;

  ${(props) => `
    background-image: url(${props.white ? SmallLogoWhiteImg : SmallLogoBlack});
    height: ${props.useViewportHeight ? '100vh' : '100%'};
  `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    background-position: 20px 20px;
  }
`;

export const MenuButton = styled.div`
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 102px 36px;
  background-attachment: fixed;
  position: absolute;
  z-index: 150;
  pointer-events: none;
  top: 0;
  left: 0;
  transition-property: opacity;
  transition-duration: 2.5s;

  background-position: calc(100% - 20px) 20px;

  ${(props) => `
    visibility: ${props.menuIsShown ? 'hidden' : 'visible'};
    background-image: url(${props.white ? MenuButtonWhiteImg : MenuButtonBlackImg});
    height: ${props.useViewportHeight ? '100vh' : '100%'};
  `}

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    background-position: calc(100% - 40px) 38px;
  }
`;

export const Tag = styled(H5)`
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 0 24px;
  margin: 2.5px 0;
  background-color: ${COLORS.LIGHT_GREY};
  color: ${COLORS.BLACK};
  border-radius: 18px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.3px;

  ${(props) =>
    props.backgroundColor &&
    `
      background-color: ${props.backgroundColor};
      color: ${COLORS.WHITE};
  `}
  ${(props) =>
    props.borderColor &&
    `
      border: 1px solid ${props.borderColor};
  `}
  ${(props) =>
    props.color &&
    `
      color: ${props.color};
  `}
`;
