export const isSSR = typeof window === 'undefined';

export const PAGES_TYPES = {
  ART: 'ART',
  CONTACT_US: 'CONTACT_US',
  IMPACT: 'IMPACT',
  LIBRARY: 'LIBRARY',
  MAP_MATCHMAKER: 'MAP_MATCHMAKER',
  TOOLS: 'TOOLS',
};

export const NEWSLETTER_LINK_PROPS = {
  href: 'https://5fcb60a5.sibforms.com/serve/MUIEAKaP3_Z2IKkdcRuZOXmfcKP13umesozFkRUK9B9m-fJZYh3sIEuHP_zeDDynxXsWZO2PsTqmFappuIUUwpoGK9-PvcP75idZHtLSqNrCFLg2UujY4UlaTUZhlM_BnsJ10t52Xwkv34W_iAk6-1Y4Q-A31UGjz140afWtWDE1dQUu0CWU3em9sRef9Owbvmy8FQyenDoXnX-Z',
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const NETWORK_FILTERS_NAMES = {
  SHOW_INDIVIDUALS: 'individuals',
  SHOW_ORGANIZATIONS: 'organizations',
  PROFILES: 'profiles',
  ORGANIZATION_TYPES: 'organization-types',
  ART_DISCIPLINES: 'arts-disciplines',
  TOPICS: 'topics',
  LOCATIONS: 'locations',
};

export const WELCOME_FINAL_OPTIONS = {
  ACTOR: 'actors-registration',
  BELIEVER: 'believers-supporter-form',
  CURIOUS: 'curious-network',
};

export const MEMBER_TYPES = {
  ORGANISATION: 'Organisation',
  INDIVIDUAL: 'Individual',
};

export const KEY_REGISTRATION_TEMPORARY_DATA = 'temporaryData';
export const KEY_WELCOME_TEMPORARY_DATA = 'welcomeTemporaryData';

export const SHORT_INPUT_MAX_LENGTH = 100;
export const DEFAULT_INPUT_MAX_LENGTH = 255;
export const LONG_INPUT_MAX_LENGTH = 1000;

export const YUP_MESSAGES = {
  REQUIRED: 'Required field',
  URL_PATTERN: 'Provide a valid URL',
};
