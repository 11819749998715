import React from 'react';

import StepInputRadio from '../StepInputRadio';

import * as Styled from './styles';

const SecondStep = ({ register, watch, errors, typeOfMemberOptions, operatingInOptions }) => {
  return (
    <Styled.GlobalContainer>
      <Styled.TitleContainer>
        <Styled.QuestionTitle>
          Select an option below to let us know more about you.
        </Styled.QuestionTitle>
      </Styled.TitleContainer>

      <Styled.QuestionsContainer>
        <StepInputRadio
          label="I identify myself as an..."
          id="typeOfMember"
          register={register}
          watch={watch}
          options={typeOfMemberOptions?.map((el) => ({
            label: el?.attributes?.label,
            id: el?.id,
          }))}
          errors={errors}
          inputType="underlined"
        ></StepInputRadio>

        <StepInputRadio
          label="I mainly operate in..."
          id="operatingIn"
          register={register}
          watch={watch}
          options={operatingInOptions?.map((el) => ({
            label: el?.attributes?.name,
            id: el?.id,
          }))}
          errors={errors}
          inputType="underlined"
        ></StepInputRadio>
      </Styled.QuestionsContainer>

      <Styled.ButtonContainer>
        <Styled.ContinueButton>Continue</Styled.ContinueButton>
      </Styled.ButtonContainer>

      <Styled.LegalInfo>
        We process your personal data. <br /> Further information can be found{' '}
        <Styled.PrivacyPolicyLink href="/privacy-policy" target="_blank">
          here
        </Styled.PrivacyPolicyLink>
      </Styled.LegalInfo>
    </Styled.GlobalContainer>
  );
};

export default SecondStep;
